<template>
  <div id="app" @click="showMore = false">
    <header :class="headerClass">
      <div class="header">
        <img class="logo" src="@/assets/logo.png" alt="" />
        <div class="nav"><a href="#link" class="link">首页</a></div>
        <div class="nav"><a class="link" href="#link1">四大功能</a></div>
        <div class="nav"><a href="#link2" class="link">助学计划</a></div>
        <div class="nav"><a href="#link3" class="link">Q&A</a></div>
        <div class="nav"><a href="#link4" class="link">关于我们</a></div>
        <div class="action">
          <!-- <div class="apply">申请加入</div> -->
          <div class="open"><a href="https://coa.mornview.com/eclassadmin" class="link">打开体验</a></div>
        </div>
        <img
          class="more"
          src="@/assets/more.png"
          alt=""
          @click.stop="showMore = true"
        />
      </div>
    </header>
    <div v-if="showMore" class="more-list">
      <div class="mini-nav"><a href="#link" class="link">首页</a></div>
      <div class="mini-nav"><a href="#link1" class="link">四大功能</a></div>
      <div class="mini-nav"><a href="#link2" class="link">助学计划</a></div>
      <div class="mini-nav"><a href="#link3" class="link">Q&A</a></div>
      <div class="mini-nav"><a href="#link4" class="link">关于我们</a></div>
      <!-- <div class="mini-nav">申请加入</div> -->
      <div class="mini-nav"><a href="https://coa.mornview.com/eclassadmin" class="link">打开体验</a></div>
    </div>

    <!-- swiper -->
    <section class="s-0-wrap" id="link">
      <img class="bg" src="@/assets/s0bg.png" alt="" />
      <div class="content s-0">
        <div
          class="
            swiper
            mySwiper
            swiper-initialized
            swiper-horizontal
            swiper-pointer-events
            swiper-backface-hidden
          "
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div>
                <p class="title">知识点打卡</p>
                <p class="desc">全学科全年级全知识点每日打卡</p>
                <div class="checkbox">
                  <div class="item">
                    <img src="@/assets/checkbox.png" alt="" />
                    <span>全学科</span>
                  </div>
                  <div class="item midd">
                    <img src="@/assets/checkbox.png" alt="" />
                    <span>全年级</span>
                  </div>
                  <div class="item">
                    <img src="@/assets/checkbox.png" alt="" />
                    <span>全知识点</span>
                  </div>
                </div>
              </div>
              <img class="banner" src="@/assets/banner1.png" alt="" />
            </div>
            <div class="swiper-slide">
              <div>
                <p class="title">全新互动课</p>
                <p class="desc">随堂练掌握牢固</p>
                <div class="checkbox">
                  <div class="item">
                    <img src="@/assets/checkbox.png" alt="" />
                    <span>全学科</span>
                  </div>
                  <div class="item midd">
                    <img src="@/assets/checkbox.png" alt="" />
                    <span>全年级</span>
                  </div>
                  <div class="item">
                    <img src="@/assets/checkbox.png" alt="" />
                    <span>全知识点</span>
                  </div>
                </div>
              </div>
              <img class="banner" src="@/assets/banner2.png" alt="" />
            </div>
            <div class="swiper-slide">
              <div>
                <p class="title">英语作文AI批改</p>
                <p class="desc">虚拟老师帮你提高写作水平</p>
                <div class="checkbox">
                  <div class="item">
                    <img src="@/assets/checkbox.png" alt="" />
                    <span>全学科</span>
                  </div>
                  <div class="item midd">
                    <img src="@/assets/checkbox.png" alt="" />
                    <span>全年级</span>
                  </div>
                  <div class="item">
                    <img src="@/assets/checkbox.png" alt="" />
                    <span>全知识点</span>
                  </div>
                </div>
              </div>
              <img
                class="banner"
                style="width: 40%; margin-right: 5%"
                src="@/assets/banner3.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="
              swiper-pagination
              swiper-pagination-bullets
              swiper-pagination-horizontal
            "
          >
            <span class="swiper-pagination-bullet"></span>
            <span class="swiper-pagination-bullet"></span>
            <span class="swiper-pagination-bullet"></span>
          </div>
        </div>
      </div>
    </section>

    <section class="s-1-wrap">
      <div class="content s-1">
        <div class="s-1-item">
          <img src="@/assets/s11.png" alt="" />
          <p>全新的教学方法</p>
        </div>
        <div class="s-1-item">
          <img src="@/assets/s12.png" alt="" />
          <p>学习资料任意下载</p>
        </div>
        <div class="s-1-item">
          <img src="@/assets/s13.png" alt="" />
          <p>视频直播任意看</p>
        </div>
      </div>
    </section>

    <section class="s-2-wrap">
      <div class="content s-2">
        <p class="title" id="link1">四大核心功能</p>
        <p class="desc">一丶丰富的单词听力知识点打卡练习</p>

        <div class="tabs">
          <img :class="coverClass1" :src="tabs1[current1].img" alt="" />
          <div class="tab-head">
            <div
              v-for="(tab, index) in tabs1"
              :key="index"
              :class="['tab', index == current1 && 'active']"
              @mouseenter="handleMouseenter1(index)"
              @click="handleMouseenter1(index)"
            >
              <p class="name">{{ tab.name }}</p>
              <p class="ddesc">{{ tab.desc }}</p>
            </div>
          </div>
        </div>

        <p class="desc spsp2">二丶强大的学习工具</p>

        <div class="tabs">
          <img :class="coverClass2" :src="tabs2[current2].img" alt="" />
          <div class="tab-head">
            <div
              v-for="(tab, index) in tabs2"
              :key="index"
              :class="['tab', index == current2 && 'active']"
              @mouseenter="handleMouseenter2(index)"
              @click="handleMouseenter2(index)"
            >
              <p class="name" :style="tab.width ? `width: ${tab.width}px` : ''">
                {{ tab.name }}
              </p>
              <p class="ddesc">{{ tab.desc }}</p>
            </div>
          </div>
        </div>

        <p class="desc spsp">三丶丰富的课程学习互动课</p>
      </div>
    </section>

    <section class="s-3-wrap">
      <img class="yixing1" src="@/assets/yixing1.png" alt="" />
      <div class="content s-3">
        <div class="left">
          <div class="line"></div>
          <p class="title">全年级全科目超过11万个知识点讲解视频</p>
          <p class="desc">
            机构入驻可自定义创建老师团队，新建学习组，实时查看组成员学习打卡情况。应用场景：班级或兴趣学习组通过老师新建的学习组邀请码加入，老师实时看到学生单词听力作文打卡情况，养成学习习惯。
          </p>
        </div>
        <img src="@/assets/s3img.png" alt="" />
      </div>
    </section>

    <section class="s-4-wrap">
      <div class="content s-4">
        <img src="@/assets/s4img.png" alt="" />
        <div class="right">
          <div class="line"></div>
          <p class="title">杭州知名英语老师系列课程</p>
          <p class="desc">杭州何老师经典英语写作提升课程</p>
        </div>
      </div>
    </section>

    <section class="s-5-wrap">
      <img class="yixing2" src="@/assets/yixing2.png" alt="" />
      <div class="content s-5">
        <div class="left">
          <div class="line"></div>
          <p class="title">经典新概念英语从入门到提升课程</p>
          <p class="desc">
            提供音频、课文跟读AI评分、单词学习闯关以及视频课程重难点讲解！
          </p>
        </div>
        <img src="@/assets/s5img.png" alt="" />
      </div>
    </section>

    <section class="s-6-wrap">
      <div class="content s-6">
        <img src="@/assets/s6img.png" alt="" />
        <div class="right">
          <div class="line"></div>
          <p class="title">少儿绘本课程</p>
          <p class="desc">热门绘本课程全英导读。</p>
        </div>
      </div>
    </section>

    <section class="s-7-wrap">
      <img class="yixing1" src="@/assets/yixing1.png" alt="" />
      <div class="content s-7">
        <div class="left">
          <div class="line"></div>
          <p class="title">不定期英语学习直播课程</p>
          <p class="desc">
            小程序内置直播功能，不定期邀请专家老师做客直播间，带来学习讲座分享！
          </p>
        </div>
        <img src="@/assets/s7img.png" alt="" />
      </div>
    </section>

    <section class="s-8-wrap">
      <div class="content s-8">
        <p class="subtitle">四丶社群功能</p>
        <img src="@/assets/s8img.png" alt="" />
        <div class="right">
          <div class="line"></div>
          <p class="title">学习组管理</p>
          <p class="desc">
            机构入驻可自定义创建老师团队，新建学习组，实时查看组成员学习打卡情况。应用场景：班级或兴趣学习组通过老师新建的学习组邀请码加入，老师实时看到学生单词听力作文打卡情况，养成学习习惯。
          </p>
        </div>
      </div>
    </section>

    <section class="s-9-wrap">
      <div class="content s-9">
        <div class="right">
          <div class="line"></div>
          <p class="title">微信群管理</p>
          <p class="desc">
            对于已建立的微信群，用户从群中打开过《边学边练小程序》，机构可通过后台实时查看群成员的学习打卡情况。应用场景：微信群较多的教育自媒体或者机构，在现有的微信群查看群成员的日常学习。
          </p>
        </div>
        <img src="@/assets/s9img.png" alt="" />
      </div>
    </section>

    <section class="s-10-wrap">
      <p class="btitle" id="link2">科技驱动，助力教学公平</p>
      <img class="ccc" src="@/assets/10bg.png" alt="" />
      <div class="content s-10">
        <img src="@/assets/s10img.png" alt="" />
        <div class="right">
          <p>1、地方农村学校或老师可申请助学计划，免费开通社群功能</p>
          <p class="midd">2、加入农村学校学习组的学生可免费试用会员功能。</p>
          <p>3、可优先参与学习直播讲座与网课学习。</p>
        </div>
      </div>
    </section>

    <section>
      <div class="content">
        <p class="qatitle" id="link3">Q&A常见问题</p>
        <div class="qa">
          <p>问：普通都是免费使用吗？</p>
          <p>答：除了视频课程与题库，所有练习以及工具都可以免费试用的。</p>
          <br />
          <p>问：知识点打卡每天可以选多少题目？</p>
          <p>
            答：在设置打卡计划按钮，可以设置每天打卡的知识点（可多选），以及坚持的天数和题目数量。
          </p>
          <br />
          <p>问：怎么下载共享平台的试卷？</p>
          <p>答：点击试卷，输入您的邮箱，系统将在10秒内发送到您的邮箱中。</p>
          <br />
          <p>问：听力练习适合什么程度的学习？</p>
          <p>
            答：听力分为三个难度，成长型，中考备考型以及高考备考型。如果是小学，可以使用成长型，多达10000听力题目可以练习。
          </p>
        </div>
      </div>
    </section>

    <footer>
      <div class="content footer">
        <div class="left">
          <p id="link4">关于我们</p>
          <p class="about">
            边学边练小程序是杭州马烽窝智慧网络科技有限公司旗下产品，团队经过多年教育行业线上业务积累，整合原有多个app模块为《边学边练》小程序，从学习-工具-练习-应用四个维度，为用户提供一站式学习平台。
          </p>
        </div>
        <div class="right">
          <div class="qrcode">
            <img width="59px" src="@/assets/qrcode.png" alt="" />
            <p style="margin-top: 16px">扫码进入《边学边练》小程序</p>
          </div>
          <div class="address">
            <p>杭州马烽窝智慧网络科技有限公司</p>
            <p>联系地址：杭州市</p>
            <p>联系QQ：（点击打开）</p>
          </div>
          <img class="flogo" height="40px" src="@/assets/flogo.png" alt="" />
        </div>
      </div>
      <div class="content hide-footer">
        <img
          height="30px"
          src="@/assets/flogo.png"
          style="margin-right: 8px"
          alt=""
        />
        <div class="address flexCenter">
          <p>杭州马烽窝智慧网络科技有限公司</p>
          <p style="margin: 0 8px">联系地址：杭州市</p>
          <p>联系QQ：（点击打开）</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMore: false,
      headerClass: [],
      headerInit: false,

      tabs1: [
        {
          name: "听力王者",
          desc: "听力高手必备,选择你适合的听力库,多达10000道理解模拟题、真题等你来刷",
          img: require("@/assets/tabs1-1.png"),
        },
        {
          name: "单词突破",
          desc: "九大词库，我们贴心准备了每个单词的解析、发音、例句、音频以及单词闯关，根据斯宾浩斯遗忘曲线定制你的单词学习。",
          img: require("@/assets/tabs1-2.png"),
        },
        {
          name: "词霸训练",
          desc: "地毯式检测单词的掌握情况，与词霸同台PK，好玩，有效！",
          img: require("@/assets/tabs1-3.png"),
        },
        {
          name: "知识点打",
          desc: "全年级全学科知识点打卡，定制你的个人学习计划，自动生成知识点掌握报告以及错题本。",
          img: require("@/assets/tabs1-4.png"),
        },
      ],
      coverClass1: ["cover", "active"],
      current1: 0,

      tabs2: [
        {
          name: "教材点读",
          desc: "包括新概念英语、剑桥英语等常用教材点读，还有各类图书音频，适合用户音频预习或磨耳朵练习。",
          img: require("@/assets/tabs2-1.png"),
        },
        {
          name: "英语作文AI批",
          desc: "根据教育部课标作文评分标准，从结构、表达、语言等多维度进行AI智能分析，包括整体点评、分句点评、涉及词汇、句式结构、内容相关性等40余项细评分维度。AI替代教师的主观判断以及大量重复性工作，减少批量批改中对细节错误的忽视。",
          img: require("@/assets/tabs2-2.png"),
        },
        {
          name: "试卷拍照去除手写",
          desc: "自动AI抹除试卷手写痕迹，还原全新试卷，试题重练没难度！",
          img: require("@/assets/tabs2-3.png"),
        },
        {
          name: "题库",
          desc: "国各省市常用出版社教材配套知识点，上千万道题目，简单操作即可组卷打印",
          img: require("@/assets/tabs2-4.png"),
        },
        {
          name: "错题本",
          desc: "每日打卡的难题错题、以及题库中用户标记的难题都将存入您的个人错题本，随时打印复习。",
          img: require("@/assets/tabs2-5.png"),
        },
      ],
      coverClass2: ["cover", "active"],
      current2: 0,
    };
  },
  mounted() {
    document.onscroll = () => {
      if (document.documentElement.scrollTop > 90) {
        if (this.headerInit) {
          return;
        }

        this.headerInit = true;
        this.headerClass = ["init"];
        setTimeout(() => {
          this.headerClass = ["init", "active"];
        }, 100);
      } else {
        this.headerClass = [];
        this.headerInit = false;
      }
    };

    setTimeout(() => {
      var swiper = new Swiper(".mySwiper", {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        autoplay: true,
        delay: 2000,
      });
    }, 100);
  },
  methods: {
    handleMouseenter1(index) {
      this.current1 = index;
      this.coverClass1 = ["cover"];
      setTimeout(() => {
        this.coverClass1 = ["cover", "active"];
      }, 100);
    },
    handleMouseenter2(index) {
      this.current2 = index;
      this.coverClass2 = ["cover"];
      setTimeout(() => {
        this.coverClass2 = ["cover", "active"];
      }, 100);
    },
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
}

body {
  color: #36324a;
  font-size: 16px;
  background: #fff;
  padding-top: 92px;
  @media screen and (max-width: 768px) {
    padding-top: 62px;
  }
}

.flexCenter {
  display: flex;
  align-items: center;
}

.content {
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1366px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 9;
  &.init {
    transform: translateY(-100%);
  }
  &.active {
    transition: transform 0.5s;
    transform: translateY(0);
    border-bottom: 1px solid #ccc;
  }
  .header {
    display: flex;
    align-items: center;
    height: 92px;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 16px;
    @media screen and (max-width: 768px) {
      height: 62px;
      justify-content: space-between;
    }
    .logo {
      height: 31px;
      margin-right: 32px;
      @media screen and (max-width: 1024px) {
        margin-right: 16px;
      }
    }
    .nav {
      margin: 0 32px;
      cursor: pointer;
      transition: color 0.15s;
      @media screen and (max-width: 1024px) {
        margin: 0 16px;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
      &.active,
      &:hover {
        color: rgb(90, 157, 242);
      }
    }
    .action {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        display: none;
      }
      .apply {
        color: rgb(90, 157, 242);
        margin-right: 16px;
      }
      .open {
        background: rgb(38, 88, 228);
        border-radius: 100px;
        padding: 4px 16px;
        color: #fff;
        font-size: 14px;
      }
    }
    .more {
      display: none;
      height: 32px;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }
}
.more-list {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 16px 0;
  background: #fff;
  width: 200px;
  z-index: 10;
  box-shadow: 0 0 5px 2px rgba(130, 130, 130, 0.3);
  .mini-nav {
    padding: 16px;
    &.active {
      color: rgb(90, 157, 242);
    }
    &:active {
      color: #fff;
      background: rgba(38, 88, 228, 0.3);
    }
  }
}

.s-0-wrap {
  position: relative;
  .bg {
    width: 100%;
  }
  .s-0 {
    position: absolute;
    // top: 3vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 9.5vw;
  }
}

.s-1-wrap {
  margin: 170px 0 220px;
  @media screen and (max-width: 768px) {
    margin: 32px 0 48px;
  }
  .s-1 {
    display: flex;
    justify-content: space-between;
    max-width: 1024px;
    .s-1-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgb(90, 157, 242);
      img {
        width: 220px;
      }
      p {
        font-size: 24px;
        margin-top: 32px;
      }
      @media screen and (max-width: 768px) {
        img {
          width: 100px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}

.s-2-wrap {
  .s-2 {
    .title {
      font-weight: bold;
      font-size: 58px;
      text-align: center;
      margin-bottom: 120px;
    }
    .desc {
      font-size: 42px;
      font-weight: bold;
      text-align: center;
    }
    .spsp {
      margin: 150px 0 25px;
    }
    .spsp2 {
      margin-top: 50px;
    }
    @media screen and (max-width: 768px) {
      .title {
        font-size: 32px;
        margin-bottom: 16px;
      }
      .desc {
        font-size: 18px;
      }
      .spsp {
        margin: 50px 0 16px;
      }
    }
    .tabs {
      display: flex;
      align-items: center;
      margin-top: 100px;
      .cover {
        width: 70%;
        &.active {
          animation: cover 0.5s ease-out;
        }
        @keyframes cover {
          0% {
            transform: translateY(-20%);
            opacity: 0;
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
      .tab {
        border-radius: 16px;
        padding: 16px;
        margin-bottom: 40px;
        &.active {
          border: 1px solid rgb(120, 93, 236);
        }
      }
      .name {
        white-space: nowrap;
        padding: 0 8px;
        height: 50px;
        border-radius: 100px;
        color: #fff;
        font-size: 28px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 34px;
        background: linear-gradient(
          45deg,
          rgb(120, 93, 236),
          rgb(151, 139, 246)
        );
      }
      .ddesc {
        font-size: 20px;
      }
      @media screen and (max-width: 768px) {
        margin-top: 0;
        flex-direction: column;
        img {
          width: 100%;
        }
        .tab {
          border-radius: 8px;
          padding: 8px;
          margin-bottom: 0;
        }
        .name {
          height: 30px;
          font-size: 18px;
          margin-bottom: 8px;
        }
        .ddesc {
          font-size: 14px;
        }
      }
    }
  }
}

.s-3-wrap {
  position: relative;
  .yixing1 {
    width: 100%;
  }
  .s-3 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      .line {
        width: 50px;
        height: 5px;
        background: rgb(67, 70, 174);
      }
      .title {
        font-size: 24px;
        margin: 35px 0 30px;
      }
      .desc {
        font-size: 16px;
        color: rgb(113, 113, 117);
      }
      @media screen and (max-width: 768px) {
        .line {
          width: 30px;
          height: 3px;
        }
        .title {
          font-size: 16px;
          margin: 8px 0 6px;
        }
        .desc {
          font-size: 12px;
        }
      }
    }
    img {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 30%;
      }
    }
  }
}

.s-4-wrap {
  position: relative;
  height: 330px;
  height: 0;
  padding-bottom: 24%;
  z-index: 1;
  .s-4 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 10%;
    img {
      width: 50%;
      transform: translateY(10%);
    }
    .line {
      width: 50px;
      height: 5px;
      background: rgb(67, 70, 174);
    }
    .title {
      font-size: 24px;
      margin: 30px 0 20px;
    }
    .desc {
      font-size: 16px;
      color: rgb(113, 113, 117);
    }
    @media screen and (max-width: 768px) {
      .line {
        width: 30px;
        height: 3px;
      }
      .title {
        font-size: 16px;
        margin: 8px 0 6px;
      }
      .desc {
        font-size: 12px;
      }
    }
  }
}

.s-5-wrap {
  position: relative;
  .yixing2 {
    width: 100%;
  }
  .s-5 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .left {
      .line {
        width: 50px;
        height: 5px;
        background: rgb(67, 70, 174);
      }
      .title {
        font-size: 24px;
        margin: 35px 0 30px;
      }
      .desc {
        font-size: 16px;
        color: rgb(113, 113, 117);
      }
      @media screen and (max-width: 768px) {
        .line {
          width: 30px;
          height: 3px;
        }
        .title {
          font-size: 16px;
          margin: 8px 0 6px;
        }
        .desc {
          font-size: 12px;
        }
      }
    }
    img {
      width: 40%;
      transform: translateY(10%);
      @media screen and (max-width: 768px) {
        width: 30%;
      }
    }
  }
}

.s-6-wrap {
  position: relative;
  height: 0;
  padding-bottom: 26%;
  z-index: 1;
  .s-6 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 10%;
    img {
      width: 50%;
    }
    .line {
      width: 50px;
      height: 5px;
      background: rgb(67, 70, 174);
    }
    .title {
      font-size: 24px;
      margin: 30px 0 20px;
    }
    .desc {
      font-size: 16px;
      color: rgb(113, 113, 117);
    }
    @media screen and (max-width: 768px) {
      .line {
        width: 30px;
        height: 3px;
      }
      .title {
        font-size: 16px;
        margin: 8px 0 6px;
      }
      .desc {
        font-size: 12px;
      }
    }
  }
}

.s-7-wrap {
  position: relative;
  .yixing1 {
    width: 100%;
  }
  .s-7 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .left {
      .line {
        width: 50px;
        height: 5px;
        background: rgb(67, 70, 174);
      }
      .title {
        font-size: 24px;
        margin: 35px 0 30px;
      }
      .desc {
        font-size: 16px;
        color: rgb(113, 113, 117);
      }
      @media screen and (max-width: 768px) {
        .line {
          width: 30px;
          height: 3px;
        }
        .title {
          font-size: 16px;
          margin: 8px 0 6px;
        }
        .desc {
          font-size: 12px;
        }
      }
    }
    img {
      width: 40%;
      transform: translateY(-10%);
      @media screen and (max-width: 768px) {
        width: 30%;
      }
    }
  }
}

.s-8-wrap {
  position: relative;
  height: 0;
  padding-bottom: 25%;
  z-index: 1;
  margin-top: 120px;
  @media screen and (max-width: 768px) {
    margin-top: 32px;
  }
  .s-8 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 15%;
    @media screen and (max-width: 1440px) {
      padding-left: 20%;
    }
    .subtitle {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -179px);
      font-size: 42px;
      font-weight: bold;
      text-align: center;
      @media screen and (max-width: 768px) {
        font-size: 18px;
        transform: translate(-50%, -57px);
      }
    }
    img {
      height: 100%;
    }
    .right {
      max-width: 250px;
      margin-left: 64px;
    }
    .line {
      width: 50px;
      height: 5px;
      background: rgb(67, 70, 174);
    }
    .title {
      font-size: 24px;
      margin: 30px 0 20px;
    }
    .desc {
      font-size: 16px;
      color: rgb(113, 113, 117);
    }
    @media screen and (max-width: 768px) {
      padding-left: 10%;

      .right {
        margin-left: 16px;
      }
      .line {
        width: 30px;
        height: 3px;
      }
      .title {
        font-size: 16px;
        margin: 8px 0 6px;
      }
      .desc {
        font-size: 12px;
      }
    }
  }
}

.s-9-wrap {
  position: relative;
  height: 0;
  padding-bottom: 25%;
  z-index: 1;
  margin-top: 60px;
  .s-9 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 15%;
    @media screen and (max-width: 1440px) {
      padding-left: 20%;
    }
    img {
      height: 100%;
    }
    .right {
      max-width: 250px;
    }
    .line {
      width: 50px;
      height: 5px;
      background: rgb(67, 70, 174);
    }
    .title {
      font-size: 24px;
      margin: 30px 0 20px;
    }
    .desc {
      font-size: 16px;
      color: rgb(113, 113, 117);
    }
    @media screen and (max-width: 768px) {
      padding-left: 10%;
      .line {
        width: 30px;
        height: 3px;
      }
      .title {
        font-size: 16px;
        margin: 8px 0 6px;
      }
      .desc {
        font-size: 12px;
      }
    }
  }
}

.s-10-wrap {
  position: relative;
  margin-top: 150px;
  @media screen and (max-width: 768px) {
    margin-top: 80px;
  }
  .ccc {
    width: 100%;
  }
  .btitle {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -80%);
    font-weight: bold;
    font-size: 58px;
    text-align: center;
    white-space: nowrap;
    @media screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
  .s-10 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    img {
      width: 35.5%;
      @media screen and (max-width: 768px) {
      }
    }
    .right {
      max-width: 500px;
      font-size: 26px;
      color: #fff;
      margin-left: 10%;
      .midd {
        margin: 30px 0;
      }
      @media screen and (max-width: 1024px) {
        font-size: 18px;
        .midd {
          margin: 15px 0;
        }
      }
      @media screen and (max-width: 768px) {
        font-size: 12px;
        .midd {
          margin: 6px 0;
        }
      }
    }
  }
}

.qatitle {
  font-size: 58px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 80px;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    margin-bottom: 24px;
  }
}
.qa {
  padding: 50px 120px;
  border-radius: 16px;
  font-size: 24px;
  box-shadow: 0 0 5px 5px rgba($color: #777777, $alpha: 0.3);
  margin-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding: 20px 40px;
    border-radius: 8px;
    font-size: 14px;
    box-shadow: 0 0 5px 0 rgba($color: #777777, $alpha: 0.3);
    margin-bottom: 20px;
  }
}

footer {
  background: rgb(60, 73, 185);
  font-size: 14px;
  color: #fff;
  .footer {
    height: 200px;
    display: flex;
    align-items: center;
    .left {
      width: 476px;
      @media screen and (max-width: 768px) {
        width: auto;
      }
      .about {
        margin-top: 16px;
        line-height: 2;
      }
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 768px) {
        display: none;
      }
      .qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .address {
        font-size: 12px;
        margin: 0 70px 0 20px;
        line-height: 1.5;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
      .flogo {
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
    }
  }
  .hide-footer {
    display: none;
    @media screen and (max-width: 1024px) {
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: 768px) {
      display: block;
      .address {
        display: block;
        padding-bottom: 16px;
        p {
          margin: 0 !important;
        }
      }
    }
  }
}
.swiper {
  width: 100%;
  height: 100%;
  color: #fff;
  .title {
    font-size: 4.5vw;
  }
  .desc {
    font-size: 2vw;
    margin: 1vw 0 3vw;
  }
  .checkbox {
    display: flex;
    font-size: 1.5vw;
    white-space: nowrap;
    .item {
      display: flex;
      align-items: center;
      img {
        width: 2vw;
        margin-right: 1vw;
      }
      &.midd {
        margin: 0 6vw;
        @media screen and (max-width: 450px) {
          margin: 0 4vw;
        }
      }
    }
  }
}
.swiper-pagination-bullet {
  width: 2vw;
  height: 2vw;
  background: none;
  border: 1px solid #fff;
  opacity: 1;
  margin: 0 16px !important;
}
.swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  .banner {
    width: 50%;
  }
}

.link {
  text-decoration: none;
  color: inherit;
}
</style>
